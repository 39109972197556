

























































































































import { defineComponent, reactive, ref } from '@vue/composition-api'
import useString from '@/use/string'
import useAttachment from '@/use/attachment'

import { AxiosInstance } from 'axios'
import moment from 'moment'
import useUser from '@/use/user'

export default defineComponent({
  components: {
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue'),
    MDatePicker: () => import('@/components/molecules/m-date-picker.vue')
  },
  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root })
    const { nl2br } = useString()
    const { uploadAttachments, truncateFileName, humanReadableFileSize, fileTypesString } = useAttachment({ root })

    const form = ref<any>(null)

    const state = reactive({
      isLoading: false,
      valid: true,
      complete: false,
      success: false,
      error: false,
      installments: [
        { value: { from: 500, to: 750 }, text: '500 zł — 750 zł' },
        { value: { from: 750, to: 1000 }, text: '750 zł — 1000 zł' },
        { value: { from: 1000, to: 1250 }, text: '1000 zł — 1250 zł' },
        { value: { from: 1250, to: 1500 }, text: '1250 zł — 1500 zł' },
        { value: { from: 1500, to: 1750 }, text: '1500 zł — 1750 zł' },
        { value: { from: 1750, to: 2000 }, text: '1750 zł — 2000 zł' },
        { value: { from: 2000 }, text: 'powyżej 2000 zł' }
      ],
      initialPayments: [
        { value: 10, text: '10%' },
        { value: 20, text: '20%' },
        { value: 30, text: '30%' },
        { value: 40, text: '40%' }
      ],
      periods: [
        { value: 24, text: '24 miesiące' },
        { value: 36, text: '36 miesięcy' },
        { value: 48, text: '48 miesięcy' },
        { value: 60, text: '60 miesięcy' },
      ],
      deadlinePicker: false
    })

    const model = reactive({
      costs: '',
      installment: { from: 2000 },
      initialPayment: 40,
      period: 24,
      service: true,
      insurance: true,
      tires: true,
      representativeness: '',
      functionality: '',
      preferences: '',
      quantities: '',
      deadline: moment().add(1, 'months').format('YYYY-MM-DD'),
      attachments: []
    })

    const rules = {
      costs: [(v: any) => !!v || 'Podaj koszt'],
      installment: [(v: any) => !!v && !!v.from || 'Wybierz przedział cenowy raty miesięcznej'],
      initialPayment: [(v: any) => !!v || 'Wybierz wartość wpłaty początkowej'],
      period: [(v: any) => !!v || 'Wybierz okres finansowania'],
      attachments: [(v: File[]) => !v || v && v.length <= 5 || 'Maksymalna ilość plików to 5.'],
      company: [(v: any) => !!v || 'Wybierz firmę']
    }

    const onSubmit = async () => {
      await form.value.validate()
      if (state.valid) state.complete = true
    }

    const goBack = () => {
      state.success = false
      state.complete = false
    }

    const addRequest = async () => {
      await form.value.validate()
      if (state.valid) {
        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

        const requestData: {
          costs?: string;
          installment?: { from?: number; to?: number };
          initialPayment?: number;
          period?: number;
          service?: boolean;
          insurance?: boolean;
          tires?: boolean;
          representativeness?: string;
          functionality?: string;
          preferences?: string;
          quantities?: string;
          deadline?: string;
          attachment?: string[];
        } = {
          costs: model.costs || undefined,
          installment: model.installment || undefined,
          initialPayment: model.initialPayment || undefined,
          period: model.period || undefined,
          service: model.service || false,
          insurance: model.insurance || false,
          tires: model.tires || false,
          representativeness: model.representativeness || undefined,
          functionality: model.functionality || undefined,
          preferences: model.preferences || undefined,
          quantities: model.quantities || undefined,
          deadline: model.deadline || undefined,
        }

        state.isLoading = true

        if (model.attachments && model.attachments.length) {
          await uploadAttachments(model.attachments)
            .then(({ data }) => {
              requestData.attachment = data.attachments.map((attachment: { id: string }) => attachment.id)
            })
            .catch(error => console.log(error))
        }

        axiosInstance
          .post('request', requestData)
          .then(() => state.success = true)
          .catch(error => console.log(error))
          .finally(() => state.isLoading = false)
      } else {
        alert('Wprowadź prawidłowe dane')
      }
    }

    const reset = () => {
      model.costs = '',
      model.installment = { from: 2000 },
      model.initialPayment = 40,
      model.period = 24,
      model.service = true,
      model.insurance = true,
      model.tires = true,
      model.representativeness = '',
      model.functionality = '',
      model.preferences = '',
      model.quantities = '',
      model.deadline = moment().add(1, 'months').format('YYYY-MM-DD')

      state.error = false
      state.valid = true
      state.isLoading = false
      state.complete = false
      state.success = false
    }

    return {
      hasAccessTo,
      hasManyCompanies,
      
      moment,

      form,
      state,
      model,
      rules,
      onSubmit,
      goBack,
      addRequest,
      reset,
      nl2br,
      truncateFileName,
      humanReadableFileSize,
      fileTypesString
    }
  }
})
